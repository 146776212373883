import React, { useState, useContext, useEffect } from "react";
import {
  Tab,
  Box
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel,
} from "@mui/lab";

import StoreContext from "../../../contexts/Store/StoreContext";
import DefaultDialog from "../../../components/Dialog";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { addLicense, updateLicense } from "./services";

import Geral from "./Geral";
import Chat from "./Chat";
import IPix from "./IPix";

const defaultValues = {
  cnpj_contrato: "",
  razao_social_contrato: "",
  cnpj_faturamento: "",
  razao_social_faturamento: "",
  nome_fantasia: "",
  max_extensions: 0,
  expiration_date: null,
  client_type: "",
  on_max_extensions: "notify",
  max_digital_ocean_s: 0,
  max_digital_ocean_m: 0,
  max_digital_ocean_l: 0,
  ipix_enabled: false,
  chat_enabled: false,
  chat_max_users: 0,
  chat_max_whatsapp_num: 0,
  chat_max_whatsapp_conversas: 0,
};

const defaultError = {
  cnpj_contrato: false,
  razao_social_contrato: false,
  cnpj_faturamento: false,
  razao_social_faturamento: false,
  nome_fantasia: false,
  expiration_date: false,
  client_type: false,
  max_extensions: false,
  on_max_extensions: false,
  max_digital_ocean_s: false,
  max_digital_ocean_m: false,
  max_digital_ocean_l: false,
  chat_max_users: false,
  chat_max_whatsapp_num: false,
  chat_max_whatsapp_conversas: false,
};

const NewLicense = (props) => {
  const [data, setData] = useState({ ...defaultValues });
  const [errors, setErrors] = useState({ ...defaultError });
  const [menuSelected, SetMenuSelected] = useState("1");
  const [loading, setLoading] = useState(false);
  const { open, setOpen, reload, type, id, oldData } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const checkFields = () => {
    let result = true;
    let erroMap = {
      cnpj_contrato: { cnpj_contrato: true },
      razao_social_contrato: { razao_social_contrato: true },
      cnpj_faturamento: { cnpj_faturamento: true },
      razao_social_faturamento: { razao_social_faturamento: true },
      client_type: { client_type: true },
    };
    Object.keys(data).forEach((d) => {
      if (
        [
          "cnpj_contrato",
          "cnpj_faturamento",
          "razao_social_contrato",
          "razao_social_faturamento",
          "client_type",
        ].includes(d)
      ) {
        if (data[d] === "") {
          setErrors((error) => ({ ...error, ...erroMap[d] }));
          setLoading(false);
          result = false;
        }
      }
    });
    return result;
  };

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    reload(true);
    setOpen(false);
    if (type === "success") {
      setSuccessAlertMsg(`Licença ${verb} com sucesso!`);
      setShowSuccessAlert(true);
    } else {
      setErrorAlertMsg(`Erro ao ${verb} a licença!`);
      setShowErrorAlert(true);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (checkFields()) {
      addLicense({ data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "cadastrada", type: "success" });
        })
        .catch((err) => {
          posAPIActions({ verb: "cadastrar", type: "error" });
        });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    if (checkFields()) {
      updateLicense({ id: id, data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "atualizada", type: "success" });
        })
        .catch((err) => {
          posAPIActions({ verb: "atualizar", type: "error" });
        });
    }
  };



  useEffect(() => {
    if (open) {
      setErrors((data) => ({ ...data, ...defaultError }));
      if (type === "update") {
        setData((data) => ({ ...data, ...oldData }));
      } else {
        setData((data) => ({ ...data, ...defaultValues }));
      }
    }
  }, [open, type, oldData]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={type === "update" ? "Atualizar Licença" : "Nova Licença"}
      loading={loading}
      type={type}
      handleUpdate={handleUpdate}
      handleSubmit={handleSubmit}
      maxWidth={"md"}
      sx={{ minHeight: "43vh" }}
    >
      <TabContext value={menuSelected}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(event, menuId) => SetMenuSelected(menuId)} aria-label="Fichas de cadastros">
            <Tab label="Geral" value="1" />
            <Tab label="IPix" value="2" />
            <Tab label="Chat" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><Geral loading={loading} errors={errors} data={data} setData={setData} /></TabPanel>
        <TabPanel value="2"><IPix loading={loading} errors={errors} data={data} setData={setData} /></TabPanel>
        <TabPanel value="3"><Chat loading={loading} errors={errors} data={data} setData={setData} /></TabPanel>
      </TabContext>
    </DefaultDialog>
  );
};

export default NewLicense;
