import React, { useContext } from 'react';
import { TextField, InputAdornment, IconButton, FormControl, Select, MenuItem, InputLabel, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import CopyAllIcon from "@mui/icons-material/CopyAll";
import InterfaceContext from '../../../contexts/Interface/InterfaceContext';


function Geral({ loading, errors, data, setData }) {
  const { CLIENT_TYPE } = useContext(InterfaceContext);

  const handleCopyCNPJ = () => {
    setData((data) => ({
      ...data,
      ...{ cnpj_faturamento: data.cnpj_contrato },
    }))
  }

  const handleCopyRazaoSocial = () => {
    setData((data) => ({
      ...data,
      ...{ razao_social_faturamento: data.razao_social_contrato },
    }))
  }
  return (
    <>
      <TextField
        autoFocus
        disabled={loading}
        error={errors.cnpj_contrato}
        fullWidth
        id="cnpj_contrato"
        label="CNPJ Contrato"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ cnpj_contrato: event.target.value },
          }))
        }
        required
        sx={{ width: { sm: "35%", xs: "100%" }, mr: "5%" }}
        value={data.cnpj_contrato}
        variant="standard"
      />
      <TextField
        disabled={loading}
        error={errors.razao_social_contrato}
        fullWidth
        id="razao_social_contrato"
        label="Razão Social Contrato"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ razao_social_contrato: event.target.value },
          }))
        }
        required
        sx={{ width: { sm: "60%", xs: "100%" } }}
        value={data.razao_social_contrato}
        variant="standard"
      />
      <TextField
        disabled={loading}
        error={errors.cnpj_faturamento}
        fullWidth
        id="cnpj_faturamento"
        label="CNPJ Faturamento"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ cnpj_faturamento: event.target.value },
          }))
        }
        required
        sx={{ width: { sm: "35%", xs: "100%" }, mr: "5%", mt: 2 }}
        value={data.cnpj_faturamento}
        variant="standard"
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="Copiar CNPJ contrato"
                onClick={handleCopyCNPJ}
                edge="end"
              >
                <CopyAllIcon />
              </IconButton>
            </InputAdornment>
        }}
      />
      <TextField
        disabled={loading}
        error={errors.razao_social_faturamento}
        fullWidth
        id="razao_social_faturamento"
        label="Razão Social Faturamento"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ razao_social_faturamento: event.target.value },
          }))
        }
        required
        sx={{ width: { sm: "60%", xs: "100%" }, mt: 2 }}
        value={data.razao_social_faturamento}
        variant="standard"
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="Copiar razão social contrato"
                onClick={handleCopyRazaoSocial}
                edge="end"
              >
                <CopyAllIcon />
              </IconButton>
            </InputAdornment>
        }}
      />
      <TextField
        disabled={loading}
        error={errors.nome_fantasia}
        fullWidth
        id="nome_fantasia"
        label="Nome Fantasia"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ nome_fantasia: event.target.value },
          }))
        }
        value={data.nome_fantasia}
        variant="standard"
        sx={{ mt: 2 }}
      />
      <FormControl variant="standard" sx={{ width: "100%", mt: 2, mr: "5%" }}>
        <InputLabel required error={errors.client_type} id="select_client_type">
          Tipo de Cliente
        </InputLabel>
        <Select
          disabled={loading}
          error={errors.client_type}
          id="client_type"
          label="Tipo de Cliente"
          labelId="select_client_type"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ client_type: event.target.value },
            }))
          }
          value={data.client_type !== null ? data.client_type : ""}
        >
          {Object.values(CLIENT_TYPE).map((client_type, key) => (
            <MenuItem key={key} value={client_type.id}>
              {client_type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ mt: 2 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Quais são os sistemas habilitados</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={data.ipix_enabled} onChange={() => setData((data) => ({
                ...data,
                ...{ ipix_enabled: !data.ipix_enabled },
              }))} name="ipix" />
            }
            label="IPix"
          />
          <FormControlLabel
            control={
              <Checkbox checked={data.chat_enabled} onChange={() => setData((data) => ({
                ...data,
                ...{ chat_enabled: !data.chat_enabled },
              }))} name="chat" />
            }
            label="Chat"
          />
        </FormGroup>
      </FormControl>
    </>
  );
}

export default Geral;