import React, { useContext, useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Stack, Chip, useMediaQuery } from "@mui/material";

import StoreContext from "../../../contexts/Store/StoreContext";

import { FilterAdvanced } from "./Advanced";
import {
  DefaultFilter,
  DefaultStartAdornment,
} from "../../../components/Filter";

import { getModulos } from "../services";

const filter_map = {
  nome: (rows, filter) => {
    return rows.filter((row) => (row.nome.includes(filter) ? true : false));
  },
};

const filters = (filter, value, rows) => {
  if (value !== null) {
    let f_filter = filter_map[filter];
    if (f_filter !== undefined) {
      return f_filter(rows, value);
    } else {
      return rows;
    }
  } else {
    return rows;
  }
};

const StartAdornment = (props) => {
  const [maxWidthChips, setMaxWidthChips] = useState(170);
  const chips = useRef([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { filter, setShowAdvanced, setFilter, refInput, disabledCheck } = props;
  // eslint-disable-next-line

  let defaultFilter = {
    nome: { nome: { value: null } },
  };

  useEffect(() => {
    let sum = 0;
    let chipsArray = chips.current.filter((n) => n !== null);
    chipsArray.forEach((c) => {
      sum += c.clientWidth;
    });
    if (refInput.current && sum >= 300) {
      setMaxWidthChips(refInput.current.offsetWidth / chipsArray.length - 20);
    } else {
      setMaxWidthChips(170);
    }
  }, [filter, refInput]);

  return (
    <DefaultStartAdornment setShowAdvanced={setShowAdvanced}>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ maxWidth: isXs ? 45 : maxWidthChips }}
      >
        {Object.keys(filter)
          .filter(
            (f) =>
              (f !== "nome" &&
                filter[f].value !== null &&
                Boolean(filter[f].value) === true) ||
              (f === "nome" &&
                disabledCheck !== false &&
                filter[f].value !== null &&
                filter[f].value.length > 0)
          )
          .map((f, key) => {
            return (
              <Chip
                key={key}
                label={filter[f].label}
                size="small"
                variant="outlined"
                onDelete={() => {
                  setFilter((filter) => ({
                    ...filter,
                    ...defaultFilter[f],
                  }));
                }}
                ref={(el) => (chips.current[key] = el)}
              />
            );
          })}
      </Stack>
    </DefaultStartAdornment>
  );
};

export const Filter = (props) => {
  const [disableQuick, setDisableQuick] = useState(false);
  const [filter, setFilter] = useState({
    nome: { value: null, label: null },
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { token } = useContext(StoreContext);
  const { rows, setRows } = props;
  const inputRef = useRef(null);
  // eslint-disable-next-line
  const [searchParams] = useSearchParams();

  const handleSetValueDefault = (event) => {
    setFilter((data) => ({
      ...data,
      ...{ nome: { value: event.target.value, label: event.target.value } },
    }));
  };

  const handleGetModulos = () => {
    getModulos({ filter: filter, token: token })
      .then((response) => setRows(response.data))
      .catch((err) => {});
  };

  useEffect(() => {
    let rows_filtered = rows;
    Object.keys(filter).forEach((f) => {
      rows_filtered = filters(f, filter[f].value, rows_filtered);
    });
    setRows(rows_filtered);
  }, [filter, rows, setRows]);

  useEffect(() => {
    setDisableQuick(false);
    if (searchParams.has("ids_license")) {
      filter.ids_license = searchParams.get("ids_license");
    } else if (searchParams.has("ids_server")) {
      filter.ids_server = searchParams.get("ids_server");
    }
  }, [filter, searchParams]);

  return (
    <>
      <DefaultFilter
        title="Modulos"
        disabledCheck={disableQuick}
        placeholder={"Filtrar Modulos por nome"}
        value={filter.nome.value !== null ? filter.nome.value : ""}
        setValue={handleSetValueDefault}
        handleFilter={handleGetModulos}
        refInput={inputRef}
        startAdornment={
          <StartAdornment
            setShowAdvanced={setShowAdvanced}
            filter={filter}
            setFilter={setFilter}
            refInput={inputRef}
            disabledCheck={disableQuick}
          />
        }
      />

      <FilterAdvanced
        anchorEl={showAdvanced}
        handleClose={() => setShowAdvanced(null)}
        filter={filter}
        setFilter={setFilter}
        handleFilter={handleGetModulos}
      />
    </>
  );
};
