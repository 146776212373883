import apiClient from "../../services/api";

export const getModulos = ({ filter, token }) => {
  let options = {
    params: filter,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.get("/modules/", options);
};
