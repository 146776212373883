import React, { useContext, useEffect, useState } from "react";
import { Box, Paper, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import BoxCustom from "../../components/Box";
import DefaultTable from "../../components/Table";
import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
import { Filter } from "./Filter";
import { getModulos } from "./services";
import { useSearchParams } from "react-router-dom";
const headCells = [
  {
    id: "servidor",
    numeric: false,
    label: "Servidor",
    actions: null,
    sortable: true,
  },
  {
    id: "nome",
    numeric: false,
    label: "Nome",
    actions: null,
    sortable: true,
  },
  {
    id: "ativo",
    numeric: false,
    label: "Ativo",
    actions: null,
    sortable: false,
  },
  {
    id: "ultimo_acesso",
    numeric: false,
    label: "Ultimo acesso",
    actions: null,
    sortable: true,
  },
];

const Modulos = () => {
  const [searchParams] = useSearchParams();
  const [rows, setRows] = useState([]);
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needReload, setNeedReload] = useState(true);
  const { token } = useContext(StoreContext);
  const { showMenu, setShowErrorAlert, setErrorAlertMsg } =
    useContext(InterfaceContext);
  const theme = useTheme();
  useEffect(() => {
    let filter = {};
    if (searchParams.has("ids_license")) {
      filter.ids_license = searchParams.get("ids_license");
    } else if (searchParams.has("ids_server")) {
      filter.ids_server = searchParams.get("ids_server");
    }
    if (needReload) {
      setLoading(true);
      getModulos({ filter: filter, token: token })
        .then((data) => {
          setRows(data.data);
          setRowsFiltered(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setErrorAlertMsg("Erro em buscar os modulos!");
          setShowErrorAlert(true);
          setLoading(false);
        });
      setNeedReload(false);
    }
  }, [token, needReload, setErrorAlertMsg, setShowErrorAlert, searchParams]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <BoxCustom theme={theme} open={showMenu}>
        <Box sx={{ width: "100%", marginBottom: 4 }}>
          <Paper
            elevation={2}
            sx={{ padding: 1, marginRight: 1, marginLeft: 1 }}
          >
            <Filter rows={rows} setRows={setRowsFiltered} reload={needReload} />
          </Paper>
        </Box>
        {loading ? (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          <DefaultTable
            rows={rowsFiltered}
            headCells={headCells}
            tableName="Modulos"
            reload={setNeedReload}
            ActionsToolbar={false}
            ActionsRow={false}
            defaultOrderBy="nome"
            checkBoxDisabled={true}
          />
        )}
      </BoxCustom>
    </Box>
  );
};

export default Modulos;
