import React from 'react';
import { TextField } from '@mui/material';


function Chat({ loading, errors, data, setData }) {
  return (
    <>
      <TextField
        disabled={loading || !data.chat_enabled}
        error={errors.chat_max_users}
        id="chat_max_users"
        label="Qtd. de usuários contratados"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ chat_max_users: event.target.value },
          }))
        }
        sx={{ width: "100%" }}
        type="number"
        value={data.chat_max_users !== "" ? data.chat_max_users : 0}
        variant="standard"
      />
      <TextField
        disabled={loading || !data.chat_enabled}
        error={errors.chat_max_whatsapp_num}
        id="chat_max_whatsapp_num"
        label="Qtd. de números no whatsapp contratados"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ chat_max_whatsapp_num: event.target.value },
          }))
        }
        sx={{ width: "100%" }}
        type="number"
        value={data.chat_max_whatsapp_num !== "" ? data.chat_max_whatsapp_num : 0}
        variant="standard"
      />
      <TextField
        disabled={loading || !data.chat_enabled}
        error={errors.chat_max_whatsapp_conversas}
        id="chat_max_whatsapp_conversas"
        label="Qtd. de conversas do whatsapp contratados"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ chat_max_whatsapp_conversas: event.target.value },
          }))
        }
        sx={{ width: "100%" }}
        type="number"
        value={data.chat_max_whatsapp_conversas !== "" ? data.chat_max_whatsapp_conversas : 0}
        variant="standard"
      />
    </>
  );
}

export default Chat;