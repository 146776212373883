import React, { useContext, useEffect, useState } from "react";
import { Box, Paper, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import BoxCustom from "../../components/Box";
import DefaultTable from "../../components/Table";
import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
// import { ActionsRow} from "./Actions";
import { Filter } from "./Filter";
import { getRamais } from "./services";
import { useSearchParams } from "react-router-dom";
const headCells = [
  {
    id: "servidor",
    numeric: false,
    label: "Servidor",
    actions: null,
    sortable: true,
  },
  {
    id: "nome",
    numeric: false,
    label: "Nome",
    actions: null,
    sortable: true,
  },
  {
    id: "numero",
    numeric: false,
    label: "Numero",
    actions: null,
    sortable: false,
  },
  {
    id: "data_hora",
    numeric: false,
    label: "Ultimo acesso",
    actions: null,
    sortable: true,
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
    actions: null,
    sortable: true,
  },
];

const Ramais = () => {
  const [searchParams] = useSearchParams();
  const [rows, setRows] = useState([]);
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needReload, setNeedReload] = useState(true);
  const { token } = useContext(StoreContext);
  const { showMenu, setShowErrorAlert, setErrorAlertMsg } =
    useContext(InterfaceContext);
  const theme = useTheme();
  useEffect(() => {
    let filter = {};
    if (searchParams.has("ids_license")) {
      filter.ids_license = searchParams.get("ids_license");
    } else if (searchParams.has("ids_server")) {
      filter.ids_server = searchParams.get("ids_server");
    }
    if (needReload) {
      setLoading(true);
      getRamais({ filter: filter, token: token }).then((data) => {
        const status_off = data.data.map((item) => {
          if (item.status === null || item.status === "") {
            return { ...item, status: "offline" };
          }
          return item;
        });
        setRows(status_off);
        setRowsFiltered(status_off);
        setLoading(false);
      });
      setNeedReload(false);
    }
  }, [token, needReload, setErrorAlertMsg, setShowErrorAlert, searchParams]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <BoxCustom theme={theme} open={showMenu}>
        <Box sx={{ width: "100%", marginBottom: 4 }}>
          <Paper
            elevation={2}
            sx={{ padding: 1, marginRight: 1, marginLeft: 1 }}
          >
            <Filter rows={rows} setRows={setRowsFiltered} reload={needReload} />
          </Paper>
        </Box>
        {loading ? (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          <DefaultTable
            rows={rowsFiltered}
            headCells={headCells}
            tableName="Ramais"
            reload={setNeedReload}
            ActionsToolbar={false}
            ActionsRow={false}
            defaultOrderBy="nome"
            keyExtractor={(row) => row.id}
            checkBoxDisabled={true}
          />
        )}
      </BoxCustom>
    </Box>
  );
};

export default Ramais;
