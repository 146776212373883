import { React } from "react";

import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { DefaultFilterAdvanced } from "../../../components/Filter/advanced";

export const FilterAdvanced = (props) => {
  const { handleClose, anchorEl, filter, setFilter } = props;

  return (
    <DefaultFilterAdvanced handleClose={handleClose} anchorEl={anchorEl}>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            id="status"
            label="Status"
            value={filter.status.value !== null ? filter.status.value : ""}
            onChange={(event) => {
              handleClose();
              setFilter((filter) => ({
                ...filter,
                ...{
                  status: {
                    value: event.target.value,
                    label: event.target.value.toUpperCase(),
                  },
                },
              }));
            }}
          >
            <MenuItem value="online">Online</MenuItem>
            <MenuItem value="offline">Offline</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </DefaultFilterAdvanced>
  );
};
