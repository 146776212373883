import React, { useContext } from 'react';
import { TextField, FormControl, Select, MenuItem, InputLabel, Divider } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import pt from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InterfaceContext from '../../../contexts/Interface/InterfaceContext';


function IPix({ loading, errors, data, setData }) {
  const { ACTION_TYPE } = useContext(InterfaceContext);
  return (
    <>
      <TextField
        disabled={loading || !data.ipix_enabled}
        error={errors.max_extensions}
        id="max_extensions"
        label="Qtd. de Ramais contratados"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_extensions: event.target.value },
          }))
        }
        sx={{ width: {sm: "45%", xs: "100%"}, mr: {sm: "5%"}}}
        type="number"
        value={data.max_extensions !== "" ? data.max_extensions : 0}
        variant="standard"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
        <DatePicker
          disabled={loading || !data.ipix_enabled}
          inputFormat="dd/MM/yyyy"
          label="Data do término do contrato"
          onChange={(newValue) => {
            setData((data) => ({
              ...data,
              ...{ expiration_date: +newValue },
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              variant="standard"
              sx={{ width: {sm: "45%", xs: "100%"} }}
              error={errors.expiration_date}
            />
          )}
          value={data.expiration_date}
        />
      </LocalizationProvider>

      <FormControl variant="standard" sx={{ width: {sm: "45%", xs: "100%"}, mt: 2 }}>
        <InputLabel
          error={errors.on_max_extensions}
          id="select_on_max_extensions"
        >
          Quando atingir o máximo de ramais:
        </InputLabel>
        <Select
          disabled={loading || !data.ipix_enabled}
          error={errors.on_max_extensions}
          id="on_max_extensions"
          label="Quando Atingir o Máximo de ramais:"
          labelId="select_on_max_extensions"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ on_max_extensions: event.target.value },
            }))
          }
          value={data.on_max_extensions !== null ? data.on_max_extensions : ""}
        >
          {Object.values(ACTION_TYPE).map((on_max_extensions, key) => (
            <MenuItem key={key} value={on_max_extensions.id}>
              {on_max_extensions.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider sx={{ mt: 4, fontWeight: "bold", display: {xs: "none", sm: "block"} }}>
        Quantidade e tipo de servidores permitidos na Digital Ocean.
      </Divider>
      <TextField
        disabled={loading || !data.ipix_enabled}
        error={errors.max_digital_ocean_s}
        helperText={"2 vCPUs - 2 GB memória RAM - 60 GB Storage"}
        id="max_digital_ocean_s"
        label="Digital Ocean Servidores: Pequeno"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_digital_ocean_s: event.target.value },
          }))
        }
        sx={{ width: {sm: "28%", xs: "100%"}, mr: {sm: "5%"}, mt: 2 }}
        type="number"
        value={data.max_digital_ocean_s !== "" ? data.max_digital_ocean_s : 0}
        variant="standard"
      />
      <TextField
        disabled={loading || !data.ipix_enabled}
        error={errors.max_digital_ocean_m}
        helperText={"2 vCPUs - 4 GB memória RAM - 80 GB Storage"}
        id="max_digital_ocean_m"
        label="Digital Ocean Servidores: Médio"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_digital_ocean_m: event.target.value },
          }))
        }
        sx={{ width:{sm: "28%", xs: "100%"}, mr: {sm: "2%"}, ml: {sm: "2%"}, mt: 2 }}
        type="number"
        value={data.max_digital_ocean_m !== "" ? data.max_digital_ocean_m : 0}
        variant="standard"
      />
      <TextField
        disabled={loading || !data.ipix_enabled}
        error={errors.max_digital_ocean_l}
        helperText={"4 vCPUs - 8 GB memória RAM - 160 GB Storage"}
        id="max_digital_ocean_l"
        label="Digital Ocean Servidores: Grande"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_digital_ocean_l: event.target.value },
          }))
        }
        sx={{ width: {sm: "30%", xs: "100%"}, ml: {sm: "5%"}, mt: 2 }}
        type="number"
        value={data.max_digital_ocean_l !== "" ? data.max_digital_ocean_l : 0}
        variant="standard"
      />
    </>
  );
}

export default IPix;