import React, { useContext, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import UpdateStatus from "../../../components/UpdateStatus";
import NewServer from "../New";

import { deleteServer } from "./services";
import { updateServer } from "../New/services";
import { LocalPhoneOutlined, WindowOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const ActionsRow = (props) => {
  const { row, reload } = props;
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const navigate = useNavigate();
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    deleteServer({
      license_id: row.id_license,
      server_id: row.id,
      token: token,
    })
      .then((data) => {
        setSuccessAlertMsg("Servidor removido com sucesso!");
        setShowSuccessAlert(true);
        reload(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em remover o Servidor!");
        setShowErrorAlert(true);
      });
  };

  const handleUpdate = ({ rowData, data }) => {
    return updateServer({
      license_id: rowData.id_license,
      server_id: rowData.id,
      data: { ...data, hostname: rowData.hostname },
      token: token,
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `hostname: ${row.hostname} - licença: ${row.id_license}`
    );
    setSuccessAlertMsg("Informações copiadas");
    setShowSuccessAlert(true);
  };

  return (
    <>
      <Tooltip
        title="Ramais"
        arrow
        onClick={() =>
          navigate({
            pathname: "/ramais/",
            search: `?ids_server=${row.id}`,
          })
        }
      >
        <IconButton color="primary" aria-label="edit">
          <LocalPhoneOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Módulos"
        arrow
        onClick={() =>
          navigate({
            pathname: "/modulos/",
            search: `?ids_server=${row.id}`,
          })
        }
      >
        <IconButton color="primary" aria-label="edit">
          <WindowOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip color="primary" title="Copiar Licença e hostname" arrow>
        <IconButton aria-label="copy" onClick={handleCopy}>
          <CopyAllIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Alterar Status"
        arrow
        onClick={() => setOpenStatusDialog(true)}
      >
        <IconButton color="primary" aria-label="edit">
          <AttachMoneyOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip color="primary" title="Editar Servidor" arrow>
        <IconButton aria-label="edit" onClick={() => setOpenUpdate(true)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip color="error" title="Apagar Servidor" arrow>
        <IconButton aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <NewServer
        open={openUpdate}
        setOpen={setOpenUpdate}
        reload={reload}
        type="update"
        id={row.id}
        oldData={row}
      />
      <UpdateStatus
        open={openStatusDialog}
        setOpen={setOpenStatusDialog}
        data={row}
        reload={reload}
        apiUpdate={handleUpdate}
      />
    </>
  );
};
