import { React } from "react";

import { Grid, TextField } from "@mui/material";

import { DefaultFilterAdvanced } from "../../../components/Filter/advanced";

export const FilterAdvanced = (props) => {
  const { handleClose, anchorEl, filter, setFilter } = props;

  return (
    <DefaultFilterAdvanced handleClose={handleClose} anchorEl={anchorEl}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          id="nome"
          label="Nome"
          fullWidth
          variant="standard"
          value={filter.nome.value !== null ? filter.nome.value : ""}
          onChange={(event) =>
            setFilter((filter) => ({
              ...filter,
              ...{
                nome: {
                  value: event.target.value,
                  label: event.target.value,
                },
              },
            }))
          }
        />
      </Grid>
    </DefaultFilterAdvanced>
  );
};
